import { useCallback, useMemo, useState, useRef } from "react";

import { headers } from "../authHeader";

const PAGE_SIZE = 10;

export default function useGetClientData() {
  const [isLoadingClients, setIsLoadingClients] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [cData, setClientData] = useState<Array<any> | null>(null);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalEntries, setTotalEntries] = useState<number>(0);
  const currentFetchId = useRef(0)

  const fetchData = useCallback(
    async (pageNumber: number, searchTerm: string | null, pageSize:number | null, orderBy: string|null, ascending: boolean) => {
      const fetchId = ++currentFetchId.current
      let url = `https://pls-api-appservice.azurewebsites.net/api/TlClient?PageSize=${!!pageSize ? pageSize : 10}&PageNumber=${pageNumber}&OrderBy=${orderBy}&Ascending=${ascending}`;

      if (searchTerm != null) {
        url += "&SearchTerm=" + searchTerm;
      }

      setIsLoadingClients(true);
      setError(null);
      try {
        const response = await fetch(url, {
          headers,
          method: "GET",
        });

        console.log(response);
        if (!response.ok) {
          throw new Error(
            "Server returned error code: " +
              response.status +
              " " +
              (await response.text())
          );
        }

        const responsejson = await response.json();
        if (fetchId === currentFetchId.current) {
          setClientData(responsejson.clients);
          setTotalEntries(responsejson.metaData.totalEntries);
          setTotalPages(Math.ceil(totalEntries / PAGE_SIZE));
          setIsLoadingClients(false);
        } else {
          console.log("Fetch Operation Expired")
        }
      } catch (error: any) {
        setError(error);
        setIsLoadingClients(false);
      }
    },
    [totalEntries]
  );

  const clientData = useMemo(() => {
    if (cData == null) {
      return null;
    }
    return cData.map((c) => {
        return {
          clientId: c.client.clientId,
          userId: c.client.userId,
          marketId: c.client.marketId,
          mkrId: c.client.mkrId,
          termId: c.client.termId,
          taxId: c.client.taxId,
          accountNo: c.client.accountno,
          company: c.client.company,
          aka: c.client.aka,
          firstname: c.client.firstname,
          lastname: c.client.lastname,
          title: c.client.title.title,
          email: c.client.email.email,
          mainPhone: c.client.mainPhone,
          address: c.client.address,
          city: c.client.city,
          stateCode: c.client.stateCode,
          zipcode: c.client.zipcode,
          primaryNote: c.client.primaryNote,
          billAddress: c.client.billAddress,
          shipAddress: c.client.shipAddress,
          shipNote: c.client.shipNote,
          secondFname: c.client.secondFname,
          secondLname: c.client.secondLname,
          secondEmail: c.client.secondEmail,
          secondPhone: c.client.secondPhone,
          secondTitle: c.client.secondTitle,
          secondNote: c.client.secondNote,
          accFname: c.client.accFname,
          accLname: c.client.accLname,
          accEmail: c.client.accEmail,
          accPhone: c.client.accPhone,
          accTitle: c.client.accTitle,
          accNote: c.client.accNote,
          finW9: c.client.finW9,
          poFname: c.client.poFname,
          poLname: c.client.poLname,
          poEmail: c.client.poEmail,
          poPhone: c.client.poPhone,
          poTitle: c.client.poTitle,
          poNote: c.client.poNote,
          phoneList: c.client.phoneList,
          mobile: c.client.mobile,
          home: c.client.home,
          homePage: c.client.homePage,
          terms: c.client.terms,
          linkedin: c.client.linkedin,
          note: c.client.note,
          shippingMethod: c.client.shippingMethod,
          status: c.client.status,
          isTax: c.client.isTax,
          enable: c.client.enable,
          isContract: c.isContract,
          isDeleted: c.isDeleted,
          balance: c.balance,
          clientLabs: {
            labId: c.clientLabs.labId,
            labName: c.clientLabs.labName,
            labAddress: c.clientLabs.labAddress,
            labCity: c.clientLabs.labCity,
            labStateCode: c.clientLabs.labStateCode,
            labZipCode: c.clientLabs.labZipcode,
            labDes: c.clientLabs.labDes,
            labNote: c.clientLabs.labNote,
            labShipAddress: c.clientLabs.labShipAddress,
            labShipNote: c.clientLabs.labShipNote,
            labFirstName: c.clientLabs.labFirstName,
            labLastName: c.clientLabs.labLastName,
            labTitle: c.clientLabs.labTitle,
            labEmail: c.clientLabs.labEmail,
            labPhone: c.clientLabs.labPhone,
            labPriNote: c.clientLabs.labPriNote,
            labNdFirstName: c.clientLabs.labNdFname,
            labNdLastName: c.clientLabs.labNdLname,
            labNdPhone: c.clientLabs.labNdPhone,
            labNdTitle: c.clientLabs.labNdTitle,
            labNdNote: c.clientLabs.labNdNote,
            labPort: c.clientLabs.labPort,
            labDemo: c.clientLabs.labDemo,
            labValidation: c.clientLabs.labValidation,
            labSystem: c.clientLabs.labSystem,
            labSystemSerial: c.clientLabs.labSysSerial,
            dateInstall: c.clientLabs.dateInstall,
            clientLabsCount: c.clientLabs.length
          }
        }
      }
    );
  }, [cData]);

  return { clientData, totalPages, totalEntries, isLoadingClients, error, fetchData };
}

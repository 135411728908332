import { useEffect, useState } from "react";

import { Box, Button, CircularProgress, Menu, MenuItem } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import { useMediaQuery, useTheme } from '@mui/material';

import { navigate } from "wouter/use-browser-location";
import styled from "styled-components";

import useGetClientData  from '../../hooks/clients/useGetClients';
import TableSearch from '../../shared/TableSearch';
import AddEditClientModal from './AddEditClientModal';
import useGetMakeUpRateData from '../../hooks/makeUpRate/useGetMakeUpRate';
import useGetMarketData from '../../hooks/market/useGetMarketData';
import useGetNetTermData from "../../hooks/netTerm/useGetNetTerm";
import useGetTaxData from "../../hooks/tax/useGetTaxData";
import useGetShippingMethod from "../../hooks/shippingMethod/useGetShippingMethodData";


type Order = 'asc' | 'desc';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#0F3354",
    color: "white",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  cursor: "pointer"
}));

const StyledTableSortLabel = styled(TableSortLabel)(({ theme }) => ({
  backgroundColor: '#0F3354',
  '&.MuiTableSortLabel-root': {
    backgroundColor: '#0F3354',
    color: 'white',
  },
  '&.MuiTableSortLabel-root.MuiButtonBase-root': {
    backgroundColor: '#0F3354',
    color: 'white',
  },
  '&.MuiTableSortLabel-root.MuiButtonBase-root.Mui-active': {
    color: 'white',
  },
  '&.MuiTableSortLabel-active': {
    fontSize: 14,
    color: 'white'
  },
    '& .MuiTableSortLabel-icon': {
      color: 'white !important',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: "rgba(0, 180, 204, 0.08)",
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function Clients() {
  const { clientData, isLoadingClients, totalEntries, fetchData } =
  useGetClientData();
  const { makeUpRateData, isLoadingMakeUpRate, fetchMakeUpRateData} = useGetMakeUpRateData();
  const { marketData, isLoadingMarket, fetchMarketData }= useGetMarketData();
  const { netTermData, isLoadingNetTerm, fetchNetTermData} = useGetNetTermData();
  const { taxData, isLoadingTax, fetchTaxData } = useGetTaxData();
  const { shippingMethodData, isLoadingShippingMethod, fetchShippingMethodData } = useGetShippingMethod();

  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<string>('company');
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const [inProgressSearchTerm, setInProgressSearchTerm] = useState<string>("");
  const [isAddClientModal, setIsAddClientModal] = useState<boolean>(false);
  const [isEditClientModal, setIsEditClientModal] = useState<boolean>(false);
  const [selectedClient, setSelectedClient] = useState<any>(null);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xl'));

  
  useEffect(() => {
    const fetchOrder = order === "asc" ? true : false; 
    fetchData(pageNumber, searchTerm, rowsPerPage, orderBy, fetchOrder);
  }, [fetchData, pageNumber, searchTerm, rowsPerPage, order, orderBy]);

  const clientTableData = clientData ?? [];

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, data: any) => {
    let updatedClientData: any = {
      clientId: data.clientId,
      marketId: data.marketId,
      taxId: data.taxId,
      termId: data.termId,
      mkrId: data.mkrId === 0 ? "" : data.mkrId,
      accountno: data.accountno,
      company: data.company,
      aka: data.aka,
      firstname: data.firstname,
      lastname: data.lastname,
      title: data.title,
      email: data.email,
      password: data.password,
      mainPhone: data.mainPhone,
      address: data.address,
      city: data.city,
      stateCode: data.stateCode,
      zipcode: data.zipcode,
      primaryNote: data.primaryNote,
      billAddress: data.billAddress,
      shipAddress: data.shipAddress,
      shipNote: data.shipNote,
      secondFname: data.secondFname,
      secondLname: data.secondLname,
      secondEmail: data.secondEmail,
      secondPhone: data.secondPhone,
      secondTitle: data.secondTitle,
      secondNote: data.secondNote,
      accFname: data.accFname,
      accLname: data.accLname,
      accEmail: data.accEmail,
      accPhone: data.accPhone,
      accTitle: data.accTitle,
      accNote: data.accNote,
      finW9: data.finW9,
      poFname: data.poFname,
      poLname: data.poLname,
      poEmail: data.poEmail,
      poPhone: data.poPhone,
      poTitle: data.poTitle,
      poNote: data.poNote,
      phoneList: data.phoneList,
      mobile: data.mobile,
      home: data.home,
      homePage: data.homePage,
      terms: data.terms,
      linkedin: data.linkedin,
      note: data.note,
      shippingMethod: data.shippingMethod,
      balance: data.balance,
      status: data.status,
      isContract: data.isContract,
      supAccountno: data.supAccountno,
    }

    for(let key in updatedClientData){
      if(updatedClientData[key] === 0 || 
        updatedClientData[key] === undefined ||
        updatedClientData[key] === null
      ){
        updatedClientData[key] = ""
      }
    }

    setSelectedClient(updatedClientData);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageNumber(newPage + 1)
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageNumber(1);
  };

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    handleRequestSort(property);
  };

  const clientStatus = (status: number)=> {
    switch(status){
      case 0:
        return 'Inactive';
      case 1:
        return 'Active';
      case 2:
        return 'Potential';
    }
  }

  const isLoadingModal = isLoadingMakeUpRate || isLoadingMarket || isLoadingNetTerm || isLoadingTax || isLoadingShippingMethod;

  const handleOpenAddClientModal = () => {
    fetchMakeUpRateData();
    fetchMarketData();
    fetchNetTermData();
    fetchTaxData();
    fetchShippingMethodData();
    setIsAddClientModal(true)
  }

  const handleCloseAddClientModal = () => {
    setIsAddClientModal(false)
    setIsEditClientModal(false)
  }

  const handleOpenEditClientModal = () => {
    handleClose()
    fetchMakeUpRateData();
    fetchMarketData();
    fetchNetTermData();
    fetchTaxData();
    fetchShippingMethodData();
    setIsEditClientModal(true)
  }

  return(
    <Box component="main" sx={{ flexGrow: 1, p: 4, marginTop: "35px" }}>
        <div style={{backgroundColor: "white", color:"#0F3354", padding: "10px 10px", fontSize:"36px", marginTop: "10px", marginBottom:"10px"}}>Clients</div>
        <div style={{backgroundColor: "white"}}>
          <div style={{marginLeft: "20px", display: "flex", alignItems: "center", justifyContent: "space-between"}}>
            <TableSearch
              setSearchTerm={setSearchTerm}
              setPageNumber={setPageNumber}
              inProgressSearchTerm={inProgressSearchTerm}
              setInProgressSearchTerm={setInProgressSearchTerm}
              page={"Clients"}
            ></TableSearch> 
            <div>
              <Button onClick={()=>handleOpenAddClientModal()} variant="contained" size="medium" style={{marginRight: "20px"}}>Add Client</Button>
            </div>
            
          </div>
            <TableContainer sx={{ height: isSmallScreen ? "58vh": "60vh", overflowY: "visible"}}>
            {isLoadingClients ? (
                  <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <CircularProgress />
                  </div>
                ) : (
                  <Table sx={{ minWidth: 650 }} size={isSmallScreen ? 'small' : 'medium'} stickyHeader>
                <TableHead >
                  <TableRow >
                    <StyledTableCell component="th" scope="row">
                      <StyledTableSortLabel
                        active={orderBy === 'company'}
                        direction={order}
                        onClick={createSortHandler('company')}                   
                        >Company
                        {orderBy === 'company' ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}                     
                      </StyledTableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell>
                      <StyledTableSortLabel
                        active={orderBy === 'firstName'}
                        direction={order}
                        onClick={createSortHandler('firstName')}                   
                        >Contact
                        {orderBy === 'firstName' ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}                     
                      </StyledTableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell>
                      <StyledTableSortLabel
                        active={orderBy === 'address'}
                        direction={order}
                        onClick={createSortHandler('address')}                   
                        >Address
                        {orderBy === 'address' ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}                     
                      </StyledTableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell>
                      <StyledTableSortLabel
                        active={orderBy === 'city'}
                        direction={order}
                        onClick={createSortHandler('city')}                   
                        >City
                        {orderBy === 'city' ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}                     
                      </StyledTableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell>
                      <StyledTableSortLabel
                        active={orderBy === 'zipcode'}
                        direction={order}
                        onClick={createSortHandler('zipcode')}                   
                        >Zip
                        {orderBy === 'zipcode' ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}                     
                      </StyledTableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell>
                      Active Labs
                    </StyledTableCell>
                    <StyledTableCell>
                      <StyledTableSortLabel
                        active={orderBy === 'status'}
                        direction={order}
                        onClick={createSortHandler('status')}                   
                        >Status
                        {orderBy === 'status' ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}                     
                      </StyledTableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell>
                      Actions
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                  <TableBody>
                    {clientTableData.map((row) => (
                      <StyledTableRow
                        key={row.company}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <StyledTableCell onClick={()=>{navigate(`/client-dashboard/${row.clientId}`)}} component="th" scope="row" >
                          {row.company}
                        </StyledTableCell>
                        <StyledTableCell onClick={()=>{navigate(`/client-dashboard/${row.clientId}`)}}>{row.firstname + " " + row.lastname}</StyledTableCell >
                        <StyledTableCell onClick={()=>{navigate(`/client-dashboard/${row.clientId}`)}}>{row.address}</StyledTableCell>
                        <StyledTableCell onClick={()=>{navigate(`/client-dashboard/${row.clientId}`)}}>{row.city}</StyledTableCell>
                        <StyledTableCell onClick={()=>{navigate(`/client-dashboard/${row.clientId}`)}} style={{width: '5px', whiteSpace: 'nowrap'}}>{row.zipcode}</StyledTableCell>
                        <StyledTableCell onClick={()=>{navigate(`/client-dashboard/${row.clientId}`)}} style={{width: '5px', whiteSpace: 'nowrap'}}>{row.clientLabs.clientLabsCount}</StyledTableCell>
                        <StyledTableCell onClick={()=>{navigate(`/client-dashboard/${row.clientId}`)}} style={{width: '5px', whiteSpace: 'nowrap'}}>{clientStatus(row.status)}</StyledTableCell>
                        <StyledTableCell style={{width: '1px', whiteSpace: 'nowrap'}}>
                          <Button
                            id="basic-button"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={e => handleClick(e, row)}
                          >
                            <MoreVertIcon/>
                          </Button>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
              </Table>
            )}
            </TableContainer>
            <hr></hr>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={totalEntries}
              rowsPerPage={rowsPerPage}
              page={pageNumber-1}
              onPageChange={(handleChangePage)}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              sx={{ boxShadow: 0 }}
            >
              <MenuItem onClick={handleOpenEditClientModal}>Edit</MenuItem>
              <MenuItem onClick={handleClose}>Delete</MenuItem>
            </Menu>
        </div>
        <AddEditClientModal 
          open={isAddClientModal || isEditClientModal} 
          onClose={handleCloseAddClientModal} 
          clientData={isEditClientModal ? selectedClient : null} 
          isLoading={isLoadingModal} 
          marketData={marketData} 
          makeUpRateData={makeUpRateData} 
          netTermData={netTermData} 
          taxData={taxData} 
          shippingMethodData={shippingMethodData}
          />

    </Box>
  )
}
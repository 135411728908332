import { useCallback, useMemo, useState, useRef } from "react";

import { headers } from "../authHeader";

const PAGE_SIZE = 10;

export default function useGetClientLabData() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [cData, setClientLabData] = useState<Array<any> | null>(null);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalEntries, setTotalEntries] = useState<number>(0);
  const currentFetchId = useRef(0)

  const fetchData = useCallback(
    async (id: string, pageNumber: number, searchTerm: string | null, pageSize:number | null, orderBy: string|null, ascending: boolean) => {
      const fetchId = ++currentFetchId.current
      let url = `https://pls-api-appservice.azurewebsites.net/api/TlClientsLab?clientId=${id}&PageSize=${!!pageSize ? pageSize : 10}&PageNumber=${pageNumber}&OrderBy=${orderBy}&Ascending=${ascending}`;
      if (searchTerm != null) {
        url += "&SearchTerm=" + searchTerm;
      }

      setIsLoading(true);
      setError(null);
      try {
        const response = await fetch(url, {
          headers,
          method: "GET",
        });

        console.log(response);
        if (!response.ok) {
          throw new Error(
            "Server returned error code: " +
              response.status +
              " " +
              (await response.text())
          );
        }

        const responsejson = await response.json();
        if (fetchId === currentFetchId.current) {
          setClientLabData(responsejson.clientLabs);
          setTotalEntries(responsejson.metaData.totalEntries);
          setTotalPages(Math.ceil(totalEntries / PAGE_SIZE));
          setIsLoading(false);
        } else {
          console.log("Fetch Operation Expired")
        }
      } catch (error: any) {
        setError(error);
        setIsLoading(false);
      }
    },
    [totalEntries]
  );

  const clientLabData = useMemo(() => {
    console.log('cLabData', cData)
    if (cData == null) {
      return null;
    }
    return cData.map((c) => {
      console.log('***c', c)
        return {
            labId: c.labId,
            clientId: c.clientId,
            labName: c.labName,
            labAddress: c.labAddress,
            labCity: c.labCity,
            labStateCode: c.labStateCode,
            labZipCode: c.labZipcode,
            labDes: c.labDes,
            labNote: c.labNote,
            labShipAddress: c.labShipAddress,
            labShipNote: c.labShipNote,
            labFirstName: c.labFirstName,
            labLastName: c.labLastName,
            labTitle: c.labTitle,
            labEmail: c.labEmail,
            labPhone: c.labPhone,
            labPriNote: c.labPriNote,
            labNdFirstName: c.labNdFname,
            labNdLastName: c.labNdLname,
            labNdPhone: c.labNdPhone,
            labNdEmail: c.labNdEmail,
            labNdTitle: c.labNdTitle,
            labNdNote: c.labNdNote,
            labPort: c.labPort,
            labDemo: c.labDemo,
            labValidation: c.labValidation,
            labSystem: c.labSystem,
            labSystemSerial: c.labSysSerial,
            labDateInstall: c.dateInstall,
        }
      }
    );
  }, [cData]);

  return { clientLabData, totalPages, totalEntries, isLoading, error, fetchData };
}
import { useCallback, useMemo, useState, useRef } from "react";

import { headers } from "../authHeader";
import { TNetTerm } from "../../utils/types/netTermTypes";

export default function useGetNetTermData() {
  const [isLoadingNetTerm, setIsLoadingNetTerm] = useState<boolean>(false);
  const [errorNetTerm, setErrorNetTerm] = useState<any>(null);
  const [netTermState, setNetTermState] = useState<Array<TNetTerm> | null>(null);
  const currentFetchId = useRef(0)

  const fetchNetTermData = useCallback(
    async () => {
      const fetchId = ++currentFetchId.current
      let url = `https://pls-api-appservice.azurewebsites.net/api/TlTermNet`;

      setIsLoadingNetTerm(true);
      setErrorNetTerm(null);
      try {
        const response = await fetch(url, {
          headers,
          method: "GET",
        });

        if (!response.ok) {
          throw new Error(
            "Server returned error code: " +
              response.status +
              " " +
              (await response.text())
          );
        }

        const responsejson = await response.json();
        if (fetchId === currentFetchId.current) {
          setNetTermState(responsejson);
          setIsLoadingNetTerm(false);
        } else {
          console.log("Fetch Operation Expired")
        }
      } catch (error: any) {
        setErrorNetTerm(error);
        setIsLoadingNetTerm(false);
      }
    },
    []
  );

  const netTermData = useMemo(() => {
    if (netTermState == null) {
      return null;
    }
    return netTermState.map((c) => {
        return {
          "termId": c.termId,
          "authorId": c.authorId,
          "termName": c.termName,
          "allowDays": c.allowDays,
          "overPercent": c.overPercent,
          "dayDiscount": c.dayDiscount,
          "discountPercent": c.discountPercent,
          "dayRemind": c.dayRemind,
          "description": c.description,
        }
      }
    );
  }, [netTermState]);

  return { netTermData, isLoadingNetTerm, errorNetTerm, fetchNetTermData };
}
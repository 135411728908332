import { useCallback, useMemo, useState, useRef } from "react";

import { headers } from "../authHeader";
import { TTax } from "../../utils/types/taxType";

export default function useGetTaxData() {
  const [isLoadingTax, setIsLoadingTax] = useState<boolean>(false);
  const [errorTax, setErrorTax] = useState<any>(null);
  const [taxState, setTaxState] = useState<Array<TTax> | null>(null);
  const currentFetchId = useRef(0)

  const fetchTaxData = useCallback(
    async () => {
      const fetchId = ++currentFetchId.current
      let url = `https://pls-api-appservice.azurewebsites.net/api/TlTax`;

      setIsLoadingTax(true);
      setErrorTax(null);
      try {
        const response = await fetch(url, {
          headers,
          method: "GET",
        });

        if (!response.ok) {
          throw new Error(
            "Server returned error code: " +
              response.status +
              " " +
              (await response.text())
          );
        }

        const responsejson = await response.json();
        if (fetchId === currentFetchId.current) {
          setTaxState(responsejson);
          setIsLoadingTax(false);
        } else {
          console.log("Fetch Operation Expired")
        }
      } catch (error: any) {
        setErrorTax(error);
        setIsLoadingTax(false);
      }
    },
    []
  );

  const taxData = useMemo(() => {
    if (taxState == null) {
      return null;
    }
    return taxState.map((c) => {
        return {
          "taxId": c.taxId,
          "taxName": c.taxName,
          "taxPercent": c.taxPercent,
          "taxAgency": c.taxAgency,
          "description": c.description,
          "status": c.status,
        }
      }
    );
  }, [taxState]);

  return { taxData, isLoadingTax, errorTax, fetchTaxData };
}
import { useCallback, useMemo, useState, useRef } from "react";

import { headers } from "../authHeader";
import { TMakeUpRate } from "../../utils/types/makeUpRateTypes";

export default function useGetMakeUpRateData() {
  const [isLoadingMakeUpRate, setIsLoadingMakeUpRate] = useState<boolean>(false);
  const [errorMakeUpRate, setErrorMakeUpRate] = useState<any>(null);
  const [makeUpRateState, setMakeUpRateState] = useState<Array<TMakeUpRate> | null>(null);
  const currentFetchId = useRef(0)

  const fetchMakeUpRateData = useCallback(
    async () => {
      const fetchId = ++currentFetchId.current
      let url = `https://pls-api-appservice.azurewebsites.net/api/TlMakeuprate`;

      setIsLoadingMakeUpRate(true);
      setErrorMakeUpRate(null);
      try {
        const response = await fetch(url, {
          headers,
          method: "GET",
        });

        if (!response.ok) {
          throw new Error(
            "Server returned error code: " +
              response.status +
              " " +
              (await response.text())
          );
        }

        const responsejson = await response.json();
        if (fetchId === currentFetchId.current) {
          setMakeUpRateState(responsejson);
          setIsLoadingMakeUpRate(false);
        } else {
          console.log("Fetch Operation Expired")
        }
      } catch (error: any) {
        setErrorMakeUpRate(error);
        setIsLoadingMakeUpRate(false);
      }
    },
    []
  );

  const makeUpRateData = useMemo(() => {
    if (makeUpRateState == null) {
      return null;
    }
    return makeUpRateState.map((c) => {
        return {
          "mkrId": c.mkrId,
          "groupName": c.groupName,
          "description": c.description,
          "percent": c.percent,
          "status": c.status,
        }
      }
    );
  }, [makeUpRateState]);

  return { makeUpRateData, isLoadingMakeUpRate, errorMakeUpRate, fetchMakeUpRateData };
}
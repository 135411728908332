import { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Modal from '@mui/material/Modal';
import { Box, Typography } from '@mui/material';
import styled from "styled-components";
import CircularProgress from '@mui/material/CircularProgress';

import loginImage from '../../assets/loginImage.jpg';
import LogoContainer from "./LoginLogo";
import useLogin from '../../hooks/auth/useLogin';


const StyledContainer = styled.div`
  display:flex;
  height: 100vh;
  width: 100vw;
  overflow-y: hidden;
  background-color: white !important;
`

const StyledTitle = styled.div`
  text-align: center;
  margin-bottom: 24px;
`

const StyledFormContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: auto;
  min-width: 550px;
`

const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 285px;
`

const StyledGradientContainer = styled.div`
  position: relative;
  flex: 1;
  height:100vh;

  img {
    width: 100%;
    height: 100vh;
  }
`;

const LogoWrapper = styled.div`
  position: absolute;
  margin-top: 10px;
  margin-left: 10px;
`;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


export default function Login() {
  const { sendLogin, isLoading } = useLogin();
  const [open, setOpen] = useState(false);
  const [, setUserName] = useState<string>("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [formData, setFormData] = useState({
    userName: "",
    password: "",
  });
  

  useEffect(() => {
    const savedUserName = localStorage.getItem("userName");
    if (savedUserName) {
      setUserName(savedUserName);
      setRememberMe(true);
    }
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const onLogin = async (e: any) => {
    e.preventDefault();

    try {
      await sendLogin(formData);

      if (rememberMe) {
        localStorage.setItem("userName", formData.userName);
      } else {
        localStorage.removeItem("userName");
        sessionStorage.setItem("userName", formData.userName);
      }
    } catch (err) {
      console.error(err);
    }
  };


  return (
    <>
      <StyledContainer>
      <LogoWrapper>
        <LogoContainer />
      </LogoWrapper>
          <StyledFormContainer className="Styled-Form-Container">
            <form onSubmit={onLogin} id="login">
            <div style={{width: "50%"}}>
              <StyledForm className="styled-form">
                <StyledTitle>
                  <div style={{fontSize: "36px", fontWeight: "bold", color:"#0F3354"}}>Sign In</div>
                  <div style={{fontSize: "20px", fontWeight: "semi-bold", marginTop: "5px", marginBottom: "15px", color:"#0F3354"}}>Sign in to stay connected</div>
                </StyledTitle>
                  <TextField
                  id="filled-error-helper-text"
                    label="Username"
                    placeholder="username"
                    variant="filled"
                    name="userName"
                    value={formData.userName}
                    onChange={handleChange}
                    required
                  />
                  <br></br>
                  <TextField
                  id="filled-error-helper-text"
                    type="password"
                    label="Password"
                    placeholder="Password"
                    variant="filled"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    required
                  />
                  <br></br>
                  <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                  <FormControlLabel control={<Checkbox defaultChecked />} label="Remember Me" />
                  <span><a onClick={handleOpen} href="/#" style={{color: "#007d8e" }}>Forgot Password</a></span>  
                  </div>

                  <br></br>            
                  <Button 
                    variant="contained" 
                    sx={{
                      backgroundColor: "#0F3354", 
                      color: "white"
                    }}
                    type="submit"
                  >
                  {isLoading ? (
                  <span>
                    <CircularProgress size= "20px" color="secondary"/>
                  </span>
                ) : (
                  "Sign In"
                )}
                  </Button>
                <br></br>
              </StyledForm>
            </div>
            </form>
          </StyledFormContainer>
          <StyledGradientContainer>
            <img src={loginImage} alt="loginImage" />
          </StyledGradientContainer>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" style={{color: "#0F3354"}}>
            Forgot Password
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Please reach out to your administrator for assistance with resetting your password.
          </Typography>
          <Box style={{display: 'flex', justifyContent: "flex-end"}}>
            <Button onClick={handleClose} style={{marginTop: "20px", backgroundColor: "#0F3354", color: "white"}}>close</Button>
          </Box>
        </Box>
      </Modal>
      </StyledContainer>
    </>
  )
}
import React, { useCallback, useEffect, useState } from 'react';

import { 
  Button, 
  CircularProgress, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle, 
  FormLabel, 
  InputLabel, 
  MenuItem, 
  RadioGroup, 
  Select, 
  Switch, 
  TextField 
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import Radio from '@mui/material/Radio';

import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

import usePostNewClient  from '../../hooks/clients/usePostClient';
import useOneClientData from '../../hooks/clients/useOneClientData';
import { TClientData } from '../../utils/types/clientTypes';
import { HTTPMethods } from '../../utils/urlPaths';


interface ModalFormProps {
  open: boolean;
  onClose: () => void;
  clientData: any | null;
  isLoading: boolean;
  marketData: any;
  makeUpRateData: any;
  netTermData: any;
  taxData: any;
  shippingMethodData: any;
}

const defaultClientData = {
    // clientId: "",
    marketId: "",
    taxId: "",
    termId: "",
    mkrId: "",
    accountno: "",
    company: "",
    aka: "",
    firstname: "",
    lastname: "",
    title: "",
    email: "",
    password: "",
    mainPhone: "",
    address: "",
    city: "",
    stateCode: "",
    zipcode: "",
    primaryNote: "",
    billAddress: "",
    shipAddress: "",
    shipNote: "",
    secondFname: "",
    secondLname: "",
    secondEmail: "",
    secondPhone: "",
    secondTitle: "",
    secondNote: "",
    accFname: "",
    accLname: "",
    accEmail: "",
    accPhone: "",
    accTitle: "",
    accNote: "",
    finW9: "",
    poFname: "",
    poLname: "",
    poEmail: "",
    poPhone: "",
    poTitle: "",
    poNote: "",
    phoneList: "",
    mobile: "",
    home: "",
    homePage: "",
    terms: "",
    linkedin: "",
    note: "",
    shippingMethod: "",
    balance: 0,
    status: "",
    isContract: true,
    supAccountno: "",
}

const AddEditClientModal: React.FC<ModalFormProps> = ({ open, onClose, clientData, isLoading, marketData, makeUpRateData, netTermData, taxData, shippingMethodData}) => {
  
  const {createClientRequest} = usePostNewClient();
  const { oneClientData } = useOneClientData();
  const [ formData, setFormData ] = useState<any>(defaultClientData);

  useEffect(() => {
    if(clientData !== null){
      setFormData(clientData) //marketId is ""
    }
  }, [clientData])

  const sendEditClientData = useCallback(
    (clientData: TClientData) => {
      oneClientData(clientData.clientId, HTTPMethods.PUT, clientData);
    },
    [oneClientData]
  );

  const onFormSubmit = (e: any) => {
    e.preventDefault();
    if(clientData !== null){
      sendEditClientData(formData)
    } else {
      createClientRequest(formData);
    }

    handleClose();
  };

  const handleClose = () => {
    setFormData({...formData, ...defaultClientData});
    onClose()
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.checked,
    });
  };

  if (!marketData || !makeUpRateData || !netTermData || !taxData || !shippingMethodData) {
    return <div>No data available</div>;
  }

  return(
      <Dialog 
        open={open} 
        maxWidth={"lg"} 
        fullWidth={true}
        sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' } }}
      >
        <DialogTitle>{clientData !== null ? "Edit Client" : "Add Client"}</DialogTitle>
        <DialogContent>
          {
            isLoading ? (
              <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <CircularProgress />
              </div>
            ) : (
          <form onSubmit={onFormSubmit}>
            <Grid container spacing={1}>
              <Grid size={6}>
                  <TextField 
                  label="Company Name" 
                  required={true}
                  value= {formData.company}
                  onChange={(e) => setFormData({...formData, company: e.target.value})}  
                  />
                  <TextField 
                    label="Aka" 
                    required={false}
                    value= {formData.aka}
                    onChange={(e) => setFormData({...formData, aka: e.target.value})} 
                    />
                  <TextField 
                    label="Address" 
                    required={false}
                    value= {formData.address}
                    onChange={(e) => setFormData({...formData, address: e.target.value})}  
                  />
                  <TextField 
                    label="City" 
                    required={false}
                    value= {formData.city}
                    onChange={(e) => setFormData({...formData, city: e.target.value})}  
                  />
                  <TextField 
                    label="State Code" 
                    required={false}
                    value= {formData.stateCode}
                    onChange={(e) => setFormData({...formData, stateCode: e.target.value})}  
                  />
                  <TextField 
                    label="Zip Code" 
                    required={false}
                    value= {formData.zipcode}
                    onChange={(e) => setFormData({...formData, zipcode: e.target.value})}  
                  />
                  <TextField 
                    label="Facility Notes" 
                    required={false}
                    value= {formData.note}
                    onChange={(e) => setFormData({...formData, note: e.target.value})}  
                  />
                  <TextField 
                  label="Billing Address" 
                  required={false}
                  value= {formData.billAddress}
                  onChange={(e) => setFormData({...formData, billAddress: e.target.value})}  
                  />
                  <FormControl sx={{ m: 1, minWidth: 220 }} required>
                  <InputLabel>Market</InputLabel>
                  <Select
                    id={formData.marketId}
                    value={formData.marketId}
                    label="Market"
                    onChange={(e)=> setFormData({...formData, marketId: e.target.value})}
                  >
                    {marketData.map((market: any, i:any)=>
                    <MenuItem key={i} value={market.marketId}>{market.marketName}</MenuItem>
                  )}
                  </Select>
                </FormControl>
                <FormControlLabel
                  control={
                    <Switch checked={formData.isContract} onChange={handleChange} name="isContract" />
                  } 
                  label="Contract"
                />
                <FormControlLabel
                  control={
                    <Switch checked={formData.isTax} onChange={handleChange} name="isTax" />
                  } 
                  label="Tax"
                />
              </Grid>
              <Grid size={6}>
                <TextField 
                  label="Balance" 
                  type="number"
                  inputMode="decimal"
                  required={false}
                  value= {formData.balance}
                  onChange={(e) => setFormData({...formData, balance: e.target.value})}  
                />
                <div style={{marginLeft: "10px"}}>
                  <FormControl required>
                    <FormLabel>Status</FormLabel>
                    <RadioGroup
                      row
                      name="status"
                      value={formData.status}
                      onChange={(e) => setFormData({...formData, status: e.target.value})}
                    >
                      <FormControlLabel value={2} control={<Radio /> } label="Potential" />
                      <FormControlLabel value={1} control={<Radio />} label="Active" />
                      <FormControlLabel value={0} control={<Radio />} label="Inactive" />
                    </RadioGroup>
                  </FormControl>
                </div>
                <TextField 
                  label="Account Number" 
                  required={false}
                  value= {formData.accountno}
                  onChange={(e) => setFormData({...formData, accountno: e.target.value})}
                />
                <TextField 
                  label="Home Page" 
                  required={false}
                  value= {formData.homePage}
                  onChange={(e) => setFormData({...formData, homePage: e.target.value})}  
                />
                <TextField 
                  label="Linkedin" 
                  required={false}
                  value= {formData.linkedin}
                  onChange={(e) => setFormData({...formData, linkedin: e.target.value})}  
                />
                <FormControl sx={{ m: 1, minWidth: 220 }} required>
                  <InputLabel>Markup Rate</InputLabel>
                    <Select
                      id={formData.mkrId}
                      value={formData.mkrId}
                      label="Markup Rate"
                      onChange={(e)=> {
                        setFormData({...formData, mkrId: e.target.value})}}
                    >
                      {makeUpRateData.map((mkr: any, i: any)=>
                      <MenuItem key={i} value={mkr.mkrId}>{mkr.groupName}</MenuItem>
                    )}
                    </Select>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 220 }} required>
                  <InputLabel>Shipping Method</InputLabel>
                  <Select
                    id={formData.shippingMethod}
                    value={formData.shippingMethod}
                    label="Shipping Method"
                    onChange={(e)=> setFormData({...formData, shippingMethod: e.target.value})}
                  >
                    {shippingMethodData.map((shipping: any, i: any)=>
                    <MenuItem key={i} value={shipping.shipMid}>{shipping.shipMethodName}</MenuItem>
                  )}
                  </Select>
                </FormControl>
                <TextField 
                  label="Ship Address" 
                  required={false}
                  value= {formData.shipAddress}
                  onChange={(e) => setFormData({...formData, shipAddress: e.target.value})}  
                />
                <TextField 
                  label="Ship Note" 
                  required={false}
                  value= {formData.shipNote}
                  onChange={(e) => setFormData({...formData, shipNote: e.target.value})}  
                />
              </Grid>
              <Grid id="primaryContact" size={6}>
                <h4>Primary Contact</h4>
                <TextField 
                label="Primary Contact First Name" 
                required={false}
                value= {formData.firstname}
                onChange={(e) => setFormData({...formData, firstname: e.target.value})}  
              />
              <TextField 
                label="Primary Contact Last Name" 
                required={false}
                value= {formData.lastname}
                onChange={(e) => setFormData({...formData, lastname: e.target.value})}  
              />
              <TextField 
                label="Primary Contact Title" 
                required={false}
                value= {formData.title}
                onChange={(e) => setFormData({...formData, title: e.target.value})}  
              />
              <TextField 
                label="Primary Title Email" 
                type="email"
                required={false}
                value= {formData.email}
                onChange={(e) => setFormData({...formData, email: e.target.value})}  
              />
              <TextField 
                label="Main Phone" 
                required={false}
                value= {formData.mainPhone}
                onChange={(e) => setFormData({...formData, mainPhone: e.target.value})}  
              />
              <TextField 
                label="Primary Note Contact Note" 
                required={false}
                value= {formData.primaryNote}
                onChange={(e) => setFormData({...formData, primaryNote: e.target.value})}  
              />
              </Grid>
              <Grid id="secondaryContact" size={6}>
                <h4>Secondary Contact</h4>
                <TextField 
                  label="Secondary Contact First Name" 
                  required={false}
                  value= {formData.secondFname}
                  onChange={(e) => setFormData({...formData, secondFname: e.target.value})}  
                />
                <TextField 
                  label="Secondary Contact Last Name" 
                  required={false}
                  value= {formData.secondLname}
                  onChange={(e) => setFormData({...formData, secondLname: e.target.value})}  
                />
                <TextField 
                  label="Secondary Contact Email" 
                  type="email"
                  required={false}
                  value= {formData.secondEmail}
                  onChange={(e) => setFormData({...formData, secondEmail: e.target.value})}  
                />
                <TextField 
                  label="Secondary Contact Phone" 
                  required={false}
                  value= {formData.secondPhone}
                  onChange={(e) => setFormData({...formData, secondPhone: e.target.value})}  
                />
                <TextField 
                  label="Secondary Contact Title" 
                  required={false}
                  value= {formData.secondTitle}
                  onChange={(e) => setFormData({...formData, secondTitle: e.target.value})}  
                />
              <TextField 
                label="Secondary Contact Notes" 
                required={false}
                value= {formData.secondNote}
                onChange={(e) => setFormData({...formData, secondNote: e.target.value})}  
              />
              </Grid>
              <Grid id="AccountsPayable" size={6}>
                <h4>Accounts Payable Contact</h4>
                <TextField 
                  label="Account First Name" 
                  required={false}
                  value= {formData.accFname}
                  onChange={(e) => setFormData({...formData, accFname: e.target.value})}  
                />
                <TextField 
                  label="Account Last Name" 
                  required={false}
                  value= {formData.accLname}
                  onChange={(e) => setFormData({...formData, accLname: e.target.value})}  
                />
                <TextField 
                  label="Account Email" 
                  type="email"
                  required={false}
                  value= {formData.accEmail}
                  onChange={(e) => setFormData({...formData, accEmail: e.target.value})}  
                />
                <TextField 
                  label="Account Phone" 
                  required={false}
                  value= {formData.accPhone}
                  onChange={(e) => setFormData({...formData, accPhone: e.target.value})}  
                />
                <TextField 
                  label="Account Title" 
                  required={false}
                  value= {formData.accTitle}
                  onChange={(e) => setFormData({...formData, accTitle: e.target.value})}  
                />
                <TextField 
                  label="w9" 
                  required={false}
                  value= {formData.finW9}
                  onChange={(e) => setFormData({...formData, finW9: e.target.value})}  
                />
                <FormControl sx={{ m: 1, minWidth: 220 }} required>
                <InputLabel>Tax</InputLabel>
                <Select
                  id={formData.taxId}
                  value={formData.taxId}
                  label="Tax"
                  onChange={(e)=> setFormData({...formData, taxId: e.target.value})}
                >
                  {taxData.map((taxVal: any, i: any)=>
                  <MenuItem key={i} value={taxVal.taxId}>{taxVal.taxName}</MenuItem>
                )}
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1, minWidth: 220 }} required>
                <InputLabel>Terms</InputLabel>
                <Select
                  id={formData.termId}
                  value={formData.termId}
                  label="Net Terms"
                  onChange={(e)=> setFormData({...formData, termId: e.target.value})}
                >
                  {netTermData.map((term: any, i: any)=>
                  <MenuItem key={i} value={term.termId}>{term.termName}</MenuItem>
                )}
                </Select>
              </FormControl>
              <TextField 
                  label="Account Note" 
                  required={false}
                  value= {formData.accNote}
                  onChange={(e) => setFormData({...formData, accNote: e.target.value})}  
              />
              </Grid>
              <Grid size={6}>
                <h4>Purchasing Contact</h4>
                <TextField 
                  label="PLS-Sup Account #" 
                  required={false}
                  value= {formData.supAccountno}
                  onChange={(e) => setFormData({...formData, supAccountno: e.target.value})}  
                />
                <TextField 
                  label="poFName" 
                  required={false}
                  value= {formData.poFname}
                  onChange={(e) => setFormData({...formData, poFname: e.target.value})}  
                />
                <TextField 
                  label="poLname" 
                  required={false}
                  value= {formData.poLname}
                  onChange={(e) => setFormData({...formData, poLname: e.target.value})}  
                />
                <TextField 
                  label="poEmail" 
                  type="email"
                  required={false}
                  value= {formData.poEmail}
                  onChange={(e) => setFormData({...formData, poEmail: e.target.value})}  
                />
                <TextField 
                  label="poPhone" 
                  required={false}
                  value= {formData.poPhone}
                  onChange={(e) => setFormData({...formData, poPhone: e.target.value})}  
                />
                <TextField 
                  label="poTitle" 
                  required={false}
                  value= {formData.poTitle}
                  onChange={(e) => setFormData({...formData, poTitle: e.target.value})}  
                />
                <TextField 
                  label="poNote" 
                  required={false}
                  value= {formData.poNote}
                  onChange={(e) => setFormData({...formData, poNote: e.target.value})}  
                />
              </Grid>
              {/* <TextField 
                label="password" 
                required={false}
                value= {formData.password}
                onChange={(e) => setFormData({...formData, password: e.target.value})}  
              /> */}
            </Grid>
          </form>
            )
        }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" onClick={onFormSubmit}>Submit</Button>
        </DialogActions>
      </Dialog>
  )
}

export default AddEditClientModal;




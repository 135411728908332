import { useCallback, useMemo, useState, useRef } from "react";

import { headers } from "../authHeader";
import { TMarket } from "../../utils/types/marketTypes";

export default function useGetMarketData() {
  const [isLoadingMarket, setIsLoadingMarket] = useState<boolean>(false);
  const [errorMarket, setErrorMarket] = useState<any>(null);
  const [marketState, setMarketState] = useState<Array<TMarket> | null>(null);
  const currentFetchId = useRef(0)

  const fetchMarketData = useCallback(
    async () => {
      const fetchId = ++currentFetchId.current
      let url = `https://pls-api-appservice.azurewebsites.net/api/TlMarket`;

      setIsLoadingMarket(true);
      setErrorMarket(null);
      try {
        const response = await fetch(url, {
          headers,
          method: "GET",
        });

        if (!response.ok) {
          throw new Error(
            "Server returned error code: " +
              response.status +
              " " +
              (await response.text())
          );
        }

        const responsejson = await response.json();
        if (fetchId === currentFetchId.current) {
          setMarketState(responsejson);
          setIsLoadingMarket(false);
        } else {
          console.log("Fetch Operation Expired")
        }
      } catch (error: any) {
        setErrorMarket(error);
        setIsLoadingMarket(false);
      }
    },
    []
  );

  const marketData = useMemo(() => {
    if (marketState == null) {
      return null;
    }
    return marketState.map((c) => {
        return {
          "marketId": c.marketId,
          "marketName": c.marketName,
          "description": c.description,
          "status": 0
        }
      }
    );
  }, [marketState]);

  return { marketData, isLoadingMarket, errorMarket, fetchMarketData };
}
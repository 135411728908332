import { useCallback, useMemo, useState, useRef } from "react";

import { headers } from "../authHeader";
import { TShippingMethod } from "../../utils/types/shippingMethodTypes";

export default function useGetShippingMethod() {
  const [isLoadingShippingMethod, setIsLoadingShippingMethod] = useState<boolean>(false);
  const [errorShippingMethod, setErrorShippingMethod] = useState<any>(null);
  const [shippingMethodState, setShippingMethodState] = useState<Array<TShippingMethod> | null>(null);
  const currentFetchId = useRef(0)

  const fetchShippingMethodData = useCallback(
    async () => {
      const fetchId = ++currentFetchId.current
      let url = `https://pls-api-appservice.azurewebsites.net/api/TlShippingMethod`;

      setIsLoadingShippingMethod(true);
      setErrorShippingMethod(null);
      try {
        const response = await fetch(url, {
          headers,
          method: "GET",
        });

        if (!response.ok) {
          throw new Error(
            "Server returned error code: " +
              response.status +
              " " +
              (await response.text())
          );
        }

        const responsejson = await response.json();
        if (fetchId === currentFetchId.current) {
          setShippingMethodState(responsejson);
          setIsLoadingShippingMethod(false);
        } else {
          console.log("Fetch Operation Expired")
        }
      } catch (error: any) {
        setErrorShippingMethod(error);
        setIsLoadingShippingMethod(false);
      }
    },
    []
  );

  const shippingMethodData = useMemo(() => {
    if (shippingMethodState == null) {
      return null;
    }
    return shippingMethodState.map((c) => {
        return {
          "shipMid": c.shipMid,
          "shipMethodName": c.shipMethodName,
          "fee": c.fee,
          "status": c.status,
        }
      }
    );
  }, [shippingMethodState]);

  return { shippingMethodData, isLoadingShippingMethod, errorShippingMethod, fetchShippingMethodData };
}
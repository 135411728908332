import { Box, Button, Menu, MenuItem } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import { useEffect, useState } from "react";
import styled from "styled-components";
import { visuallyHidden } from '@mui/utils';
import { useMediaQuery, useTheme } from '@mui/material';

import useGetInventoryData from '../../hooks/inventory/useGetInventory';
import TableSearch from '../../shared/TableSearch';

type Order = 'asc' | 'desc';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#0F3354",
    color: "white",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableSortLabel = styled(TableSortLabel)(({ theme }) => ({
  backgroundColor: '#0F3354',
  '&.MuiTableSortLabel-root': {
    backgroundColor: '#0F3354',
    color: 'white',
  },
  '&.MuiTableSortLabel-root.MuiButtonBase-root': {
    backgroundColor: '#0F3354',
    color: 'white',
  },
  '&.MuiTableSortLabel-root.MuiButtonBase-root.Mui-active': {
    color: 'white',
  },
  '&.MuiTableSortLabel-active': {
    fontSize: 14,
    color: 'white'
  },
    '& .MuiTableSortLabel-icon': {
      color: 'white !important',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: "rgba(0, 180, 204, 0.08)",
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function Inventory() {
  const { inventoryData, totalEntries, fetchInventoryData } = useGetInventoryData();
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<string>('company');
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const [inProgressSearchTerm, setInProgressSearchTerm] = useState<string>("");


  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xl'));

  
  useEffect(() => {
    const fetchOrder = order === "asc" ? true : false; 

    fetchInventoryData(pageNumber, searchTerm, rowsPerPage, orderBy, fetchOrder);
  },  [fetchInventoryData, pageNumber, searchTerm, rowsPerPage, order, orderBy]);


  const inventoryTableData = inventoryData ?? [];

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageNumber(newPage + 1)
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageNumber(1);
  };

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    handleRequestSort(property);
  };

  return(
    <Box component="main" sx={{ flexGrow: 1}}>
        <div style={{backgroundColor: "white"}}>
          <div style={{marginLeft: "20px", display: "flex", alignItems: "center", justifyContent: "space-between"}}>
            <TableSearch
              setSearchTerm={setSearchTerm}
              setPageNumber={setPageNumber}
              inProgressSearchTerm={inProgressSearchTerm}
              setInProgressSearchTerm={setInProgressSearchTerm}
              page={"Inventory"}
            ></TableSearch> 
            <div>
              <Button variant="contained" size="medium" style={{marginRight: "20px"}}>Add Inventory</Button>
            </div>
            
          </div>
            <TableContainer sx={{ height: isSmallScreen ? "58vh": "60vh", overflowY: "visible"}}>
              <Table sx={{ minWidth: 650 }} size={isSmallScreen ? 'small' : 'medium'} stickyHeader>
                <TableHead >
                  <TableRow>
                    <StyledTableCell component="th" scope="row">
                      <StyledTableSortLabel
                        active={orderBy === 'stockno'}
                        direction={order}
                        onClick={createSortHandler('stockno')}
                        sx={{minWidth: "200px"}}                   
                        >PLS Part #
                        {orderBy === 'stockno' ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}                     
                      </StyledTableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell>
                      <StyledTableSortLabel
                        active={orderBy === 'partno'}
                        direction={order}
                        onClick={createSortHandler('partno')}                   
                        >Part #
                        {orderBy === 'partno' ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}                     
                      </StyledTableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell>
                      <StyledTableSortLabel
                        active={orderBy === 'mfgno'}
                        direction={order}
                        onClick={createSortHandler('mfgno')}                   
                        >Mfg Part #
                        {orderBy === 'mfgno' ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}                     
                      </StyledTableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell>
                      <StyledTableSortLabel
                        active={orderBy === 'description'}
                        direction={order}
                        onClick={createSortHandler('description')}                   
                        >Description
                        {orderBy === 'description' ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}                     
                      </StyledTableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell>
                      <StyledTableSortLabel
                        active={orderBy === 'vendor'}
                        direction={order}
                        onClick={createSortHandler('vendor')}                   
                        >Vendor
                        {orderBy === 'vendor' ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}                     
                      </StyledTableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell>
                      <StyledTableSortLabel
                        active={orderBy === 'poQtyExchange'}
                        direction={order}
                        onClick={createSortHandler('poQtyExchange')}                   
                        >QTY
                        {orderBy === 'poQtyExchange' ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}                     
                      </StyledTableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell>
                      <StyledTableSortLabel
                        active={orderBy === 'reorder'}
                        direction={order}
                        onClick={createSortHandler('reorder')}                   
                        >Re-Order Point
                        {orderBy === 'reorder' ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}                     
                      </StyledTableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell>
                      <StyledTableSortLabel
                        active={orderBy === 'status'}
                        direction={order}
                        onClick={createSortHandler('status')}                   
                        >Status
                        {orderBy === 'status' ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}                     
                      </StyledTableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell>
                      <StyledTableSortLabel
                        active={orderBy === 'shelfLife'}
                        direction={order}
                        onClick={createSortHandler('shelfLife')}                   
                        >Shelf Life
                        {orderBy === 'shelfLife' ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}                     
                      </StyledTableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell>
                      <StyledTableSortLabel
                        active={orderBy === 'inspection'}
                        direction={order}
                        onClick={createSortHandler('inspection')}                   
                        >Inspection
                        {orderBy === 'inspection' ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}                     
                      </StyledTableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell>
                      <StyledTableSortLabel
                        active={orderBy === 'certReq'}
                        direction={order}
                        onClick={createSortHandler('certReq')}                   
                        >Cert Req'd
                        {orderBy === 'certReq' ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}                     
                      </StyledTableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell>
                      <StyledTableSortLabel
                        active={orderBy === 'type'}
                        direction={order}
                        onClick={createSortHandler('type')}                   
                        >Type
                        {orderBy === 'type' ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}                     
                      </StyledTableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell>
                      Actions
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {inventoryTableData.map((row) => (
                    <StyledTableRow
                      key={row.stockno}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <StyledTableCell component="th" scope="row">
                        {row.stockno}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.partno}
                      </StyledTableCell>
                      <StyledTableCell >{row.mfgno}</StyledTableCell >
                      <StyledTableCell >{row.description}</StyledTableCell>
                      <StyledTableCell >{row.vendorId}</StyledTableCell>
                      <StyledTableCell  style={{width: '5px', whiteSpace: 'nowrap'}}>{row.poQtyExchange}</StyledTableCell>
                      <StyledTableCell  style={{width: '5px', whiteSpace: 'nowrap'}}>{row.reorder}</StyledTableCell>
                      <StyledTableCell  style={{width: '5px', whiteSpace: 'nowrap'}}>{row.status}</StyledTableCell>
                      <StyledTableCell  style={{width: '5px', whiteSpace: 'nowrap'}}>{row.shelfLife}</StyledTableCell>
                      <StyledTableCell  style={{width: '5px', whiteSpace: 'nowrap'}}>{row.inspection}</StyledTableCell>
                      <StyledTableCell  style={{width: '5px', whiteSpace: 'nowrap'}}>{row.certReq}</StyledTableCell>
                      <StyledTableCell  style={{width: '5px', whiteSpace: 'nowrap'}}>{row.type}</StyledTableCell>
                      <StyledTableCell style={{width: '1px', whiteSpace: 'nowrap'}}>
                        <Button
                          id="basic-button"
                          aria-controls={open ? 'basic-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? 'true' : undefined}
                          onClick={handleClick}
                        >
                          <MoreVertIcon/>
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <hr></hr>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={totalEntries}
              rowsPerPage={rowsPerPage}
              page={pageNumber-1}
              onPageChange={(handleChangePage)}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              sx={{ boxShadow: 0 }}
            >
              <MenuItem onClick={handleClose}>Edit</MenuItem>
              <MenuItem onClick={handleClose}>Delete</MenuItem>
            </Menu>
        </div>
        <Box>
    </Box>
    </Box>
  )
}